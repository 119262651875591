import React from "react";
import PrivateRoute from "components/PrivateRoute";
// Containers
import Proxy from "containers/Session/Proxy";
import UserLogged from "../../../containers/userLogged";
const SP = () => {
  return (
    <UserLogged>
      <PrivateRoute component={Proxy} />
    </UserLogged>
  );
};

export default SP;
